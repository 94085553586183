import { Slot } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';
import type * as React from 'react';

import { Loader2 } from 'lucide-react';
import { cn } from '~/utils/cn';
import { textVariants } from './Typography';

const buttonVariants = cva(
  'relative inline-flex items-center justify-center gap-1 overflow-hidden whitespace-nowrap rounded-xl font-bold shadow-[0px_4px_0px_0px_rgba(25,_48,_53,_0.04)] transition-colors duration-500 ease-in-out focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-focus disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-[hsla(193,_53%,_8%,_1)]',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-[hsla(38,_91%,_73%,_1)]',
        tertiary: 'bg-surface-on text-foreground hover:bg-surface',
        danger: 'bg-error text-on hover:opacity-90',
        link: 'text-foreground underline underline-offset-4 shadow-none hover:no-underline',
      },
      size: {
        xl: cn('h-[54px] p-3.5', textVariants({ size: 'xl' })),
        l: cn('h-[51px] p-3.5', textVariants({ size: 'l' })),
        m: cn('h-[43px] p-2.5', textVariants({ size: 'l' })),
        s: cn('h-[41px] p-2.5', textVariants({ size: 'm' })),
        icon: 'aspect-square size-[51px]',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'l',
    },
  },
);

export interface ButtonProps
  extends React.ComponentPropsWithRef<'button'>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = ({
  ref,
  className,
  variant,
  size,
  asChild = false,
  loading,
  children,
  ...props
}: ButtonProps) => {
  const Comp = asChild ? Slot : 'button';

  const buttonVariant = cn(buttonVariants({ variant, size, className }));

  return (
    <Comp className={buttonVariant} ref={ref} {...props} disabled={loading || props.disabled}>
      {children}

      {loading ? (
        <span
          className={cn(
            'absolute inset-0 flex items-center justify-center',
            buttonVariant.split(' ').filter((f) => f.startsWith('bg-')) || 'bg-background',
          )}
        >
          <Loader2 className="size-4 animate-spin" size={24} />
        </span>
      ) : null}
    </Comp>
  );
};
Button.displayName = 'Button';

export { Button, buttonVariants };
